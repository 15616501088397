@font-face {
  font-family: "Space Grotesk";
  src: url("./assets/fonts/SpaceGrotesk-Regular.eot");
  src: url("./assets/fonts/SpaceGrotesk-Regular.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/SpaceGrotesk-Regular.woff2") format("woff2"),
  url("./assets/fonts/SpaceGrotesk-Regular.woff") format("woff"),
  url("./assets/fonts/SpaceGrotesk-Regular.ttf") format("truetype"),
  url("./assets/fonts/SpaceGrotesk-Regular.svg#SpaceGrotesk-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("./assets/fonts/SpaceGrotesk-Medium.eot");
  src: url("./assets/fonts/SpaceGrotesk-Medium.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/SpaceGrotesk-Medium.woff2") format("woff2"),
  url("./assets/fonts/SpaceGrotesk-Medium.woff") format("woff"),
  url("./assets/fonts/SpaceGrotesk-Medium.ttf") format("truetype"),
  url("./assets/fonts/SpaceGrotesk-Medium.svg#SpaceGrotesk-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("./assets/fonts/SpaceGrotesk-Bold.eot");
  src: url("./assets/fonts/SpaceGrotesk-Bold.eot?#iefix") format("embedded-opentype"),
  url("./assets/fonts/SpaceGrotesk-Bold.woff2") format("woff2"),
  url("./assets/fonts/SpaceGrotesk-Bold.woff") format("woff"),
  url("./assets/fonts/SpaceGrotesk-Bold.ttf") format("truetype"),
  url("./assets/fonts/SpaceGrotesk-Bold.svg#SpaceGrotesk-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
